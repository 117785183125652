import {useCallback, useRef, useState} from 'react';
import {PREFFIX_TO_FILTER_KEY, PREFIX_TO_PLACEHOLDER} from '~/shared/constants/job-board';
import {Button, DropdownMenu, FormControl, IconButton, SearchField} from '@job-ish/ui/components';
import {IconFilterSearch, IconSearch} from '@tabler/icons-react';

import type {KeyboardEvent, ReactNode} from 'react';

type JobBoardFilterSearchField = {
	value?: string[];
	onChange: (value: string[]) => void;
	input: string;
	onInputChange: (searchTerm: string) => void;
	prefix: keyof typeof PREFFIX_TO_FILTER_KEY;
	onPrefixChange: (value: keyof typeof PREFFIX_TO_FILTER_KEY) => void;
	onFormSubmit: () => void;
	loading?: boolean;
	emptyFilterFallback?: ReactNode;
};

export const JobBoardFilterSearchField = ({
	value = [],
	onChange,
	prefix,
	onPrefixChange,
	input,
	onInputChange,
	onFormSubmit,
	loading,
}: JobBoardFilterSearchField) => {
	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleAddSearchTerm = useCallback(
		(searchTerm: string) => {
			const filteredSearchTerm = searchTerm.replaceAll('"', '').trim();
			if (filteredSearchTerm === '') return;
			if (!value.includes(filteredSearchTerm)) {
				onChange([...value, `${PREFFIX_TO_FILTER_KEY[prefix]}${filteredSearchTerm}`]);
			}
			onInputChange('');
		},
		[onChange, onInputChange, prefix, value],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === ',' || event.key === '|') {
				event.preventDefault();
				handleAddSearchTerm(input);
			}

			if (event.key === 'Enter') {
				setSubmitting(true);
				event.preventDefault();
				onFormSubmit();
			}

			if (event.key === 'Escape') {
				onInputChange('');
			}
		},
		[handleAddSearchTerm, input, onFormSubmit, onInputChange],
	);

	const focusInput = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleInputChange = useCallback(
		(searchTerm: string) => {
			onInputChange(searchTerm);
		},
		[onInputChange],
	);

	const handleOnCloseAutoFocus = useCallback(
		(event: Event) => {
			{
				event.preventDefault();
				if (/AppleWebKit/.test(navigator.userAgent) && /iPhone|iPod|iPad/.test(navigator.userAgent)) {
					return;
				}
				focusInput();
			}
		},
		[focusInput],
	);

	return (
		<>
			<div className="flex grow flex-wrap items-center gap-1">
				<DropdownMenu onOpenChange={open => (submitting ? setSubmitting(false) : setOpen(open))} open={open}>
					<DropdownMenu.Trigger asChild>
						<IconButton aria-label="Open search options" icon={IconFilterSearch} size="sm" />
					</DropdownMenu.Trigger>
					<DropdownMenu.Content
						align="start"
						className="ml-0.5 w-72 sm:w-96"
						onCloseAutoFocus={handleOnCloseAutoFocus}
					>
						<DropdownMenu.RadioGroup
							className="p-0"
							onValueChange={value => onPrefixChange(value as keyof typeof PREFFIX_TO_FILTER_KEY)}
							value={prefix}
						>
							<DropdownMenu.Label className="flex flex-col">
								<div>Search Options</div>
								<div className="text-xs text-mauve10">
									Jobs are matched against the selected search option in a case-insensitive manner. Results
									are based on a complete match of your search term. Combine multiple search terms to narrow
									your search results.
								</div>
							</DropdownMenu.Label>
							<DropdownMenu.RadioItem className="h-fit" value="keyword">
								<div className="flex flex-col whitespace-normal">
									<div>Keyword</div>
									<div className="text-xs text-mauve11">
										Search for a keyword or phrase in the job description
									</div>
								</div>
							</DropdownMenu.RadioItem>
							<DropdownMenu.Separator />
							<DropdownMenu.RadioItem className="h-fit" value="exclude">
								<div className="flex flex-col whitespace-normal">
									<div>Exclude</div>
									<div className="text-xs text-mauve11">
										Exclude results that contain the specified keyword or phrase in the job description
									</div>
								</div>
							</DropdownMenu.RadioItem>
							<DropdownMenu.Separator />
							<DropdownMenu.RadioItem className="h-fit" value="title">
								<div className="flex flex-col whitespace-normal">
									<div>Job Title</div>
									<div className="text-xs text-mauve11">Search for text in the job title</div>
								</div>
							</DropdownMenu.RadioItem>
						</DropdownMenu.RadioGroup>
					</DropdownMenu.Content>
				</DropdownMenu>
				<FormControl className="shrink grow">
					<SearchField
						aria-label="Search Jobs"
						className="w-full min-w-0 [&>div]:h-3 [&>svg:first-child]:hidden [&>svg:first-child]:sm:inline [&_input]:w-0"
						clearable
						label="Search Jobs"
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						placeholder={PREFIX_TO_PLACEHOLDER[prefix]}
						ref={inputRef}
						size="sm"
						suffix={
							input && (
								<Button onPress={() => handleAddSearchTerm(input)} size="xs">
									Add
								</Button>
							)
						}
						value={input}
					/>
				</FormControl>
				<Button
					className="w-fit"
					color="primary"
					iconLeft={() => <IconSearch className="inline h-4 w-4 sm:hidden" />}
					loading={loading}
					onPress={() => setOpen(false)}
					size="sm"
					type="submit"
				>
					<span className="hidden sm:inline">Search</span>
				</Button>
			</div>
		</>
	);
};
