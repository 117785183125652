'use client';

import {Button, Card} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconRocket} from '@tabler/icons-react';

import {SubscriptionDialog} from './subscription-dialog';
import type {SubscriptionDialogProps} from './subscription-dialog';

export const JobBoardFooterCTA = () => {
	const {show: showDialog} = useModal();

	return (
		<div className="w-full px-4">
			<Card
				bordered
				className="mx-auto flex w-full items-center justify-between bg-gradient-to-tr from-violet7 via-blue7 to-plum7 py-8 sm:flex-row"
			>
				<div className="flex flex-col">
					<Card.Header className="flex-col gap-0 text-2xl font-semibold sm:items-start">
						<Card.Title className="flex flex-col text-center sm:text-left">
							<span>Get hired faster.</span>
							<span>Accelerate your career.</span>
						</Card.Title>
					</Card.Header>
					<Card.Body className="flex flex-col text-center">
						Sign up today and get access to every feature.
					</Card.Body>
				</div>
				<Card.Footer className="flex flex-col justify-center gap-3">
					<Button
						as="a"
						className="mx-auto w-64"
						color="primary"
						iconRight={IconRocket}
						onPress={() => showDialog<SubscriptionDialogProps>(SubscriptionDialog)}
						size="lg"
					>
						Get Started Now
					</Button>
				</Card.Footer>
			</Card>
		</div>
	);
};
