'use client';

import {startTransition, useCallback, useContext, useMemo, useState} from 'react';
import {useDebounce} from 'react-use';
import type {SubscriptionDialogProps} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {SubscriptionDialog} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {useFilterState} from '~/app/(unauthenticated)/job-board/_/hooks/use-filter-state';
import {useJobBoardFilter} from '~/app/(unauthenticated)/job-board/_/hooks/use-job-board-filter';
import {KeywordsDialog} from '~/shared/components/dialogs/keywords';
import {PREFFIX_TO_FILTER_KEY} from '~/shared/constants/job-board';
import {PATHS} from '~/shared/constants/paths';
import {
	useJobBoardCities,
	useJobBoardCountries,
	useJobBoardIndustries,
	useJobBoardJobTypes,
	useJobBoardRegions,
} from '~/shared/data/job-board';
import {useSubscription} from '~/shared/data/subscription';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {JobBoardFilterContext} from '~/shared/providers/job-board-filter-provider';
import {isFilterValueDefined} from '~/shared/utils/job-board';
import {
	Badge,
	Button,
	Combobox,
	CopyButton,
	FormControl,
	IconButton,
	Indicator,
	Label,
	labelStyles,
	RadioGroup,
	Select,
	Sheet,
	Slider,
	Switch,
	TextButton,
	TextField,
	Tooltip,
} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {postData} from '@job-ish/utilities/data';
import {getEnvironmentBaseUrl} from '@job-ish/utilities/url';
import {
	IconAdjustments,
	IconBuildingCommunity,
	IconBuildingFactory2,
	IconBuildings,
	IconCalendar,
	IconClearAll,
	IconClock,
	IconFlag,
	IconInfoCircle,
	IconMap2,
	IconMapPin,
	IconSelector,
	IconSparkles,
} from '@tabler/icons-react';
import omit from 'lodash.omit';
import uniqBy from 'lodash.uniqby';
import {useParams, useRouter} from 'next/navigation';
import {twMerge} from 'tailwind-merge';

import {CompanySelect} from './company-select';
import {JobBoardFilterSearchField} from './search-field';

const EXPERIENCE_LEVEL_OPTIONS = [
	{id: '', name: 'Any Experience Level'},
	{id: 'EN', name: 'Entry Level'},
	{id: 'MI', name: 'Mid Level'},
	{id: 'SE', name: 'Senior Level'},
	{id: 'EX', name: 'Executive Level'},
] as const;

const DATE_POSTED_OPTIONS = [
	{id: 2, name: 'Last 48 Hours'},
	{id: 7, name: 'Last Week'},
	{id: 14, name: 'Last 14 Days'},
	{id: 30, name: 'Last 30 Days'},
] as const;

const COMPANY_SIZE_OPTIONS = [
	{id: 100, name: 'Small Business'},
	{id: 1500, name: 'Medium Business'},
	{id: 2000, name: 'Mid-Market Enterprise'},
	{id: 1, name: 'Large Enterprise'},
] as const;

export const JobBoardFilter = () => {
	const {filter, updateFilter, clearFilter, shareableSearchParams} = useJobBoardFilter();
	const {loading, setLoading} = useContext(JobBoardFilterContext);
	const [generateKeywordsLoading, setGenerateKeywordsLoading] = useState(false);
	const [generateKeywordsDialogOpen, setGenerateKeywordsDialogOpen] = useState(false);
	const [searchFieldInput, setSearchFieldInput] = useState('');
	const [prefix, setPrefix] = useState<keyof typeof PREFFIX_TO_FILTER_KEY>('keyword');
	const router = useRouter();
	const params = useParams();
	const {user} = useSupabase();
	const {data: subscription} = useSubscription();
	const {filterState, setFilterState} = useFilterState();
	const [filtersOpen, setFiltersOpen] = useState(false);

	const {show: showDialog} = useModal();

	const [citySearch, setCitySearch] = useState('');
	const [debouncedCitySearch, setDebouncedCitySearch] = useState('');
	useDebounce(
		() => {
			setDebouncedCitySearch(citySearch);
		},
		200,
		[citySearch],
	);

	const {data: jobBoardJobTypes} = useJobBoardJobTypes();
	const {data: jobBoardCountries} = useJobBoardCountries();
	const {data: jobBoardIndustries} = useJobBoardIndustries();
	const {data: jobBoardCities, isFetching: isLoadingCities} = useJobBoardCities(
		debouncedCitySearch,
		filterState.countryCode,
	);
	const {data: jobBoardRegions} = useJobBoardRegions();

	const getFormattedSearchTerms = useCallback(
		(searchFieldInput: string) =>
			searchFieldInput
				.replaceAll('|', ',')
				.replaceAll(' OR ', ',')
				.split(',')
				.filter(v => v.trim() !== '')
				.map(v => (v.startsWith('-') ? `-"${v.slice(1).trim()}"` : `"${v.trim()}"`)),
		[],
	);

	const handleUpdateFilter = useCallback(() => {
		updateFilter(
			{
				...filterState,
				description: [
					...new Set([
						...filterState.description,
						...getFormattedSearchTerms(
							searchFieldInput ? `${PREFFIX_TO_FILTER_KEY[prefix]}${searchFieldInput}` : '',
						),
					]),
				],
			},
			() => {
				setLoading(false);
			},
		);
	}, [filterState, getFormattedSearchTerms, prefix, searchFieldInput, setLoading, updateFilter]);

	const handleApplySearch = useCallback(() => {
		setLoading(true);
		setSearchFieldInput('');
		handleUpdateFilter();
		startTransition(() => {
			if (params.id) {
				router.prefetch(PATHS.JobBoard);
				router.push(PATHS.JobBoard);
			}
			router.refresh();
		});
	}, [params.id, setLoading, handleUpdateFilter, router]);

	const countryDisplayValue = useMemo(
		() => jobBoardCountries?.data.find(country => country.code === filterState.countryCode)?.name,
		[jobBoardCountries, filterState.countryCode],
	);

	const cityDisplayValue = useMemo(
		() =>
			jobBoardCities?.data.results?.find(jobBoardCity => jobBoardCity.name === filterState.cityName)?.name ||
			filterState.cityName,
		[jobBoardCities, filterState.cityName],
	);

	const industryDisplayValue = useMemo(
		() => jobBoardIndustries?.data.find(industry => industry.id === filterState.companyIndustryId)?.name,
		[jobBoardIndustries, filterState.companyIndustryId],
	);

	const regionDisplayValue = useMemo(
		() => jobBoardRegions?.data.find(region => region.id === filterState.regionId)?.name,
		[jobBoardRegions, filterState.regionId],
	);

	const locationSelected = useMemo(
		() => !!filterState.countryCode || !!filterState.cityName,
		[filterState.countryCode, filterState.cityName],
	);

	const regionSelected = useMemo(
		() => filterState.regionId !== undefined && filterState.regionId !== null,
		[filterState.regionId],
	);

	const handleClearSearch = useCallback(() => {
		if (params.id) {
			router.prefetch(PATHS.JobBoard);
			router.push(PATHS.JobBoard);
		}
		setLoading(true);
		clearFilter(() => {
			setLoading(false);
		});
	}, [clearFilter, params.id, router, setLoading]);

	const searchValue = useMemo(
		() => filterState.description.map(value => value.replaceAll('"', '')),
		[filterState.description],
	);

	const handleDismiss = useCallback(
		(searchTerm: string) => {
			setFilterState(prev => ({
				...prev,
				description: getFormattedSearchTerms(searchValue.filter(value => value !== searchTerm).join(',')),
			}));
		},
		[getFormattedSearchTerms, searchValue, setFilterState],
	);

	const filterApplied = useMemo(
		() => Object.entries(omit(filter, ['page'])).some(([key, value]) => isFilterValueDefined(key, value)),
		[filter],
	);

	const showFilterIndicator = useMemo(
		() =>
			Object.entries(omit(filter, ['page', 'description'])).some(([key, value]) =>
				isFilterValueDefined(key, value),
			),
		[filter],
	);

	const handleGenerateKeywords = useCallback(
		async (resumeId: number) => {
			setGenerateKeywordsLoading(true);
			startTransition(async () => {
				const keywords = await postData<string[]>({
					url: '/api/generate/keywords',
					data: {resume_id: resumeId},
				});

				if (keywords) {
					setFilterState(prev => ({
						...prev,
						description: getFormattedSearchTerms(keywords.join(',')),
					}));
				}

				setGenerateKeywordsLoading(false);
			});
		},
		[getFormattedSearchTerms, setFilterState],
	);

	const handleGenerateKeywordsDialogOpenChange = useCallback(
		(open: boolean) => {
			if (open && (!user || subscription?.status !== 'active')) {
				showDialog<SubscriptionDialogProps>(SubscriptionDialog);
				return;
			}
			setGenerateKeywordsDialogOpen(open);
		},
		[user, subscription?.status, showDialog],
	);

	return (
		<div className="flex flex-col gap-2">
			<form
				className="relative flex flex-col gap-1"
				onSubmit={event => {
					event.preventDefault();
					handleApplySearch();
				}}
			>
				<div className="flex w-full flex-col">
					<div className="flex w-full items-start gap-1 p-1">
						<Sheet onOpenChange={setFiltersOpen} open={filtersOpen}>
							<div className="relative">
								<Sheet.Trigger asChild>
									<IconButton aria-label="Open job board filters menu" icon={IconAdjustments} size="sm" />
								</Sheet.Trigger>
								{showFilterIndicator && (
									<Indicator className="absolute -right-0.5 -top-0.5" color="danger" size="sm" />
								)}
							</div>
							<Sheet.Content blur className="px-0 sm:w-96" side="left">
								<Sheet.Title className="px-4">Job Filters</Sheet.Title>
								<Sheet.Description asChild className="flex shrink flex-col gap-4">
									<div className="flex flex-col gap-4 overflow-y-hidden">
										<p className="px-4">Tailor your job search with personalized filters</p>
										<div className="overflow-y-auto">
											<div className="flex flex-col gap-4 px-4">
												<FormControl
													className="[&>label]:justify-between"
													inlineLabel
													label={
														<div className="flex flex-col">
															<span>Remote Only</span>
															<span className="text-xs text-mauve11">Show only remote jobs</span>
														</div>
													}
													labelPosition="left"
												>
													<Switch
														checked={filterState.hasRemote}
														onCheckedChange={value =>
															setFilterState(prev => ({
																...prev,
																hasRemote: value === false ? undefined : value,
															}))
														}
													/>
												</FormControl>
												<FormControl
													label={
														<div className="flex flex-col">
															<div className="flex items-center justify-between">
																<span>Minimum Salary</span>
																<span>
																	{Intl.NumberFormat('en-US', {
																		style: 'currency',
																		currency: 'USD',
																		minimumFractionDigits: 0,
																		maximumFractionDigits: 0,
																	}).format(filterState.minSalary || 0)}
																	+
																</span>
															</div>
															<span className="text-xs text-mauve11">
																Filter job listings by minimum salary
															</span>
														</div>
													}
												>
													<Slider
														className="pt-2"
														defaultValue={[0]}
														max={500_000}
														min={0}
														onValueChange={value =>
															setFilterState(prev => ({...prev, minSalary: value.at(0)}))
														}
														step={1000}
														value={filterState.minSalary ? [filterState.minSalary] : [0]}
													/>
												</FormControl>
												<FormControl
													label={
														<div className="flex flex-col">
															<span>Experience Level</span>
															<span className="text-xs text-mauve11">
																Select the experience level that best matches your qualifications
															</span>
														</div>
													}
												>
													<RadioGroup
														onValueChange={value =>
															setFilterState(prev => ({...prev, experienceLevel: value}))
														}
														value={filterState.experienceLevel}
													>
														{EXPERIENCE_LEVEL_OPTIONS.map(level => (
															<Label className="self-start" inline key={level.id}>
																{level.name}
																<RadioGroup.Item value={level.id} />
															</Label>
														))}
													</RadioGroup>
												</FormControl>
												<div className="flex flex-col gap-2">
													<div className={labelStyles()}>
														<div className="flex flex-col">
															<span className="text-base text-mauve12">Job Criteria</span>
															<span className="text-xs text-mauve11">
																Refine job listings using your preferred criteria
															</span>
														</div>
													</div>
													<Select
														onValueChange={value => setFilterState(prev => ({...prev, typeId: value || ''}))}
														value={filterState.typeId}
													>
														<Select.Trigger
															className="w-full"
															clearable
															placeholder="Select job type"
															prefix={<IconClock className="h-4 w-4" />}
														>
															{
																jobBoardJobTypes?.data.find(
																	jobType => jobType.id?.toString() === filterState.typeId,
																)?.name
															}
														</Select.Trigger>
														<Select.Content className="z-50 w-72">
															<Select.Group>
																<Select.Label>Job Type</Select.Label>
																{jobBoardJobTypes?.data.map(jobType => (
																	<Select.Item key={jobType.id} value={jobType.id.toString()}>
																		{jobType.name}
																	</Select.Item>
																))}
															</Select.Group>
														</Select.Content>
													</Select>
													<Select
														onValueChange={value =>
															setFilterState(prev => ({
																...prev,
																maxAge: value === undefined ? undefined : Number(value),
															}))
														}
														value={filterState.maxAge?.toString()}
													>
														<Select.Trigger
															className="w-full"
															clearable
															placeholder="Select date posted"
															prefix={<IconCalendar className="h-4 w-4" />}
														>
															{DATE_POSTED_OPTIONS.find(option => option.id === filterState.maxAge)?.name ||
																'Date Posted'}
														</Select.Trigger>
														<Select.Content className="z-50 w-72">
															<Select.Group>
																<Select.Label>Date Posted</Select.Label>
																{DATE_POSTED_OPTIONS.map(option => (
																	<Select.Item
																		className="flex w-full items-center justify-between"
																		key={option.id}
																		onSelect={() => setFilterState(prev => ({...prev, maxAge: option.id}))}
																		value={option.id.toString()}
																	>
																		{option.name}
																	</Select.Item>
																))}
															</Select.Group>
														</Select.Content>
													</Select>
												</div>
												<div className="flex flex-col gap-2">
													<div className={labelStyles()}>
														<div className="flex flex-col">
															<span className="text-base text-mauve12">Job Location</span>
															<span className="text-xs text-mauve11">
																Refine job listings using your location preferences
															</span>
														</div>
													</div>
													<Combobox>
														<Combobox.Trigger
															aria-label="Open the region combobox"
															className="w-full rounded-md"
															disabled={locationSelected}
														>
															<TextField
																aria-label="Region"
																className={twMerge(
																	'w-full [&>input]:truncate',
																	!locationSelected &&
																		!filterState.location &&
																		'opacity-100 [&>input]:opacity-100',
																)}
																clearable
																disabled={locationSelected || !!filterState.location}
																forceShowClearButton
																key={`${!!filterState.regionId}`}
																onChange={() =>
																	setFilterState(prev => ({
																		...prev,
																		regionId: undefined,
																		cityName: '',
																		countryCode: '',
																	}))
																}
																placeholder="Select region"
																prefix={<IconMap2 className="h-4 w-4 shrink-0" />}
																readonly
																suffix={<IconSelector className="h-4 w-4 shrink-0" />}
																value={regionDisplayValue}
															/>
														</Combobox.Trigger>
														<Combobox.Content
															align="start"
															className="z-50 w-64"
															hideWhenDetached
															side="bottom"
														>
															<Combobox.Input
																className="rounded-b-none rounded-t-sm"
																placeholder="Search regions..."
															/>
															<Combobox.Empty>No regions found</Combobox.Empty>
															<Combobox.List className="max-h-40 p-1">
																{jobBoardRegions?.data.map(region => (
																	<Combobox.Item
																		checked={region.id === filterState.regionId}
																		key={region.id}
																		onSelect={() => setFilterState(prev => ({...prev, regionId: region.id}))}
																		value={region.name}
																	>
																		{region.name}
																	</Combobox.Item>
																))}
															</Combobox.List>
														</Combobox.Content>
													</Combobox>
													<Combobox>
														<Combobox.Trigger
															aria-label="Open the country combobox"
															className="w-full rounded-md"
															disabled={regionSelected || !!filterState.location}
														>
															<TextField
																aria-label="Country"
																className={twMerge(
																	'w-full [&>input]:truncate',
																	!regionSelected &&
																		!filterState.location &&
																		'opacity-100 [&>input]:opacity-100',
																)}
																clearable
																disabled={regionSelected || !!filterState.location}
																forceShowClearButton
																key={`${!!filterState.countryCode}`}
																onChange={value =>
																	setFilterState(prev => ({
																		...prev,
																		countryCode: value,
																		cityName: '',
																		regionId: undefined,
																	}))
																}
																placeholder="Select country"
																prefix={<IconFlag className="h-4 w-4 shrink-0" />}
																readonly
																suffix={<IconSelector className="h-4 w-4 shrink-0" />}
																value={countryDisplayValue}
															/>
														</Combobox.Trigger>
														<Combobox.Content
															align="start"
															className="z-50 w-72"
															hideWhenDetached
															side="bottom"
														>
															<Combobox.Input
																className="rounded-b-none rounded-t-sm"
																placeholder="Search countries..."
															/>
															<Combobox.Empty>No countries found</Combobox.Empty>
															<Combobox.List className="max-h-40 p-1">
																{jobBoardCountries?.data.map(country => (
																	<Combobox.Item
																		checked={country.code === filterState.countryCode}
																		key={country.code}
																		onSelect={() =>
																			setFilterState(prev => ({
																				...prev,
																				countryCode: country.code,
																				cityName: '',
																				regionId: undefined,
																			}))
																		}
																		value={country.name}
																	>
																		{country.name}
																	</Combobox.Item>
																))}
															</Combobox.List>
														</Combobox.Content>
													</Combobox>
													<Combobox>
														<Combobox.Trigger
															aria-label="Open the city combobox"
															className="w-full rounded-md"
															disabled={regionSelected || !!filterState.location}
														>
															<TextField
																aria-label="City Name"
																className={twMerge(
																	'w-full [&>input]:truncate',
																	!regionSelected &&
																		!filterState.location &&
																		'opacity-100 [&>input]:opacity-100',
																)}
																clearable
																disabled={regionSelected || !!filterState.location}
																forceShowClearButton
																key={`${!!filterState.cityName}`}
																onChange={value =>
																	setFilterState(prev => ({...prev, cityName: value, regionId: undefined}))
																}
																placeholder="Select city"
																prefix={<IconBuildingCommunity className="h-4 w-4 shrink-0" />}
																readonly
																suffix={<IconSelector className="h-4 w-4 shrink-0" />}
																value={cityDisplayValue}
															/>
														</Combobox.Trigger>
														<Combobox.Content
															align="start"
															className="z-50 w-72"
															hideWhenDetached
															shouldFilter={false}
															side="bottom"
														>
															<Combobox.Input
																className="rounded-b-none rounded-t-sm"
																loading={isLoadingCities}
																onValueChange={setCitySearch}
																placeholder="Search cities..."
																value={citySearch}
															/>
															<Combobox.Empty>
																{isLoadingCities ? 'Loading cities...' : 'No cities found'}
															</Combobox.Empty>
															<Combobox.List className="relative max-h-40 p-1">
																{uniqBy(jobBoardCities?.data.results, 'name')
																	.filter(
																		city =>
																			!filterState.countryCode ||
																			city.country.code === filterState.countryCode,
																	)
																	.map(jobBoardCity => (
																		<Combobox.Item
																			checked={jobBoardCity.name === filterState.cityName}
																			key={jobBoardCity.geonameid}
																			onSelect={() =>
																				setFilterState(prev => ({...prev, cityName: jobBoardCity.name}))
																			}
																			value={jobBoardCity.name}
																		>
																			{jobBoardCity.name}
																		</Combobox.Item>
																	))}
															</Combobox.List>
														</Combobox.Content>
													</Combobox>
													<TextField
														aria-label="Location"
														className="w-full"
														clearable
														disabled={regionSelected || locationSelected}
														forceShowClearButton
														onChange={value => setFilterState(prev => ({...prev, location: value}))}
														placeholder="Enter location (e.g. St. Louis)"
														prefix={<IconMapPin className="h-4 w-4 shrink-0" />}
														suffix={
															<Tooltip>
																<Tooltip.Trigger>
																	<IconInfoCircle className="h-4 w-4 shrink-0" />
																</Tooltip.Trigger>
																<Tooltip.Content className="z-[100] w-72">
																	Use this field if you&apos;re having trouble finding a specific city or
																	region. Enter a location like &quot;New York, NY&quot; or
																	&quot;California&quot; to see job listings in that area.
																</Tooltip.Content>
															</Tooltip>
														}
														value={filterState.location}
													/>
												</div>
												<div className="flex flex-col gap-2">
													<div className={labelStyles()}>
														<div className="flex flex-col">
															<span className="text-base text-mauve12">Company Criteria</span>
															<span className="text-xs text-mauve11">
																Refine job listings using your preferred company criteria
															</span>
														</div>
													</div>
													<Select
														onValueChange={value =>
															setFilterState(prev => ({
																...prev,
																companyMaxSize: value === undefined ? undefined : Number(value),
															}))
														}
														value={filterState.companyMaxSize?.toString()}
													>
														<CompanySelect
															onValueChange={value =>
																setFilterState(prev => ({
																	...prev,
																	companyName: value?.name || '',
																	companyId: value?.id,
																}))
															}
															value={{
																name: filterState.companyName,
																id: filterState.companyId,
															}}
														/>
														<Select.Trigger
															className="w-full"
															clearable
															placeholder="Select company size"
															prefix={<IconBuildings className="h-4 w-4" />}
														>
															{
																COMPANY_SIZE_OPTIONS.find(size => size.id === filterState.companyMaxSize)
																	?.name
															}
														</Select.Trigger>
														<Select.Content className="z-50 w-72">
															<Select.Group>
																<Select.Label>Company Size</Select.Label>
																{COMPANY_SIZE_OPTIONS.map(size => (
																	<Select.Item
																		key={size.id}
																		onSelect={() =>
																			setFilterState(prev => ({...prev, companyMaxSize: size.id}))
																		}
																		value={size.id.toString()}
																	>
																		{size.name}
																	</Select.Item>
																))}
															</Select.Group>
														</Select.Content>
													</Select>
													<Combobox>
														<Combobox.Trigger
															aria-label="Open the industry combobox"
															className="w-full rounded-md"
														>
															<TextField
																aria-label="Industry"
																className="w-full opacity-100 [&>input]:truncate [&>input]:opacity-100"
																clearable
																forceShowClearButton
																key={`${!!filterState.companyIndustryId}`}
																onChange={() =>
																	setFilterState(prev => ({...prev, companyIndustryId: undefined}))
																}
																placeholder="Select industry"
																prefix={<IconBuildingFactory2 className="h-4 w-4 shrink-0" />}
																readonly
																suffix={<IconSelector className="h-4 w-4 shrink-0" />}
																value={industryDisplayValue}
															/>
														</Combobox.Trigger>
														<Combobox.Content align="start" className="z-50 w-72" hideWhenDetached side="top">
															<Combobox.Input
																className="rounded-b-none rounded-t-sm"
																placeholder="Search industries..."
															/>
															<Combobox.Empty>No industries found</Combobox.Empty>
															<Combobox.List className="max-h-40 p-1">
																{jobBoardIndustries?.data.map(industry => (
																	<Combobox.Item
																		checked={industry.id === filterState.companyIndustryId}
																		key={industry.id}
																		onSelect={() =>
																			setFilterState(prev => ({
																				...prev,
																				companyIndustryId: Number(industry.id),
																			}))
																		}
																		title={industry.name}
																		value={industry.name}
																	>
																		<div className="truncate">{industry.name}</div>
																	</Combobox.Item>
																))}
															</Combobox.List>
														</Combobox.Content>
													</Combobox>
												</div>
											</div>
										</div>
									</div>
								</Sheet.Description>
								<Sheet.Footer>
									<div className="flex items-center gap-4">
										<TextButton
											color="neutral"
											onPress={() =>
												setFilterState(currFilterState => ({
													description: currFilterState.description,
													typeId: '',
													hasRemote: undefined,
													experienceLevel: '',
													maxAge: undefined,
													countryCode: '',
													cityName: '',
													companyMaxSize: undefined,
													companyIndustryId: undefined,
													location: '',
													companyName: '',
													companyId: undefined,
												}))
											}
										>
											Reset
										</TextButton>
										<Button
											color="primary"
											onPress={() => {
												setFiltersOpen(false);
												setTimeout(handleApplySearch, 150);
											}}
										>
											Apply Filters
										</Button>
									</div>
								</Sheet.Footer>
							</Sheet.Content>
						</Sheet>
						<JobBoardFilterSearchField
							input={searchFieldInput}
							loading={loading}
							onChange={value =>
								setFilterState(prev => ({
									...prev,
									description: getFormattedSearchTerms(value.join(',')),
								}))
							}
							onFormSubmit={handleApplySearch}
							onInputChange={setSearchFieldInput}
							onPrefixChange={setPrefix}
							prefix={prefix}
							value={filterState.description.map(v => v.replaceAll('"', ''))}
						/>
					</div>
					<div className="flex h-8 gap-1 overflow-x-auto p-0.5">
						{searchValue.length > 0 ? (
							searchValue.map((searchTerm, index) => (
								<Badge
									className="h-fit max-w-full"
									color={searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.exclude) ? 'danger' : 'primary'}
									dismissable
									key={`${searchTerm}-${index}`}
									onDismiss={() => handleDismiss(searchTerm)}
									size="sm"
								>
									<span className="font-bold">
										{searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.exclude)
											? 'Exclude:'
											: searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.title)
												? 'Title:'
												: 'Keyword:'}
									</span>
									<span className="truncate">
										{searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.exclude)
											? searchTerm.slice(PREFFIX_TO_FILTER_KEY.exclude.length)
											: searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.title)
												? searchTerm.slice(PREFFIX_TO_FILTER_KEY.title.length)
												: searchTerm.slice(PREFFIX_TO_FILTER_KEY.keyword.length)}
									</span>
								</Badge>
							))
						) : (
							<KeywordsDialog
								loading={generateKeywordsLoading}
								onOpenChange={handleGenerateKeywordsDialogOpenChange}
								onSubmit={data => data.resume_id && handleGenerateKeywords(data.resume_id)}
								open={generateKeywordsDialogOpen || generateKeywordsLoading}
								trigger={
									<Badge
										className="m-0.5 h-fit min-h-0"
										color="secondary"
										interactive
										prefix={<IconSparkles className="h-4 w-4 shrink-0 fill-plum3 stroke-plum11" />}
										size="sm"
									>
										Generate Keywords
									</Badge>
								}
							/>
						)}
					</div>
				</div>
				{filterApplied && (
					<div className="flex w-full items-center justify-between gap-3">
						<CopyButton
							intent="ghost"
							size="xs"
							text={`${getEnvironmentBaseUrl()}${PATHS.JobBoard.slice(1)}?${shareableSearchParams}`}
						>
							Share Search
						</CopyButton>
						<Button iconRight={IconClearAll} intent="ghost" onPress={handleClearSearch} size="xs">
							Reset Search
						</Button>
					</div>
				)}
			</form>
		</div>
	);
};
