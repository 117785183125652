import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const switchStyles = cva(
	'relative h-5 min-w-[2.25rem] w-fit rounded-full focus-visible:outline flex items-center justify-center select-none',
	{
		variants: {
			checked: {true: '', false: 'bg-mauve7 hover:bg-mauve8 focus-visible:outline-mauve8'},
			disabled: {true: 'opacity-40 pointer-events-none'},
			readonly: {true: 'opacity-70 pointer-events-none'},
			color: {primary: '', secondary: '', info: '', success: '', warning: '', danger: ''},
		},
		compoundVariants: [
			{
				checked: true,
				color: 'primary',
				className: 'bg-violet9 hover:bg-violet10 focus-visible:outline-violet8',
			},
			{checked: true, color: 'secondary', className: 'bg-plum9 hover:bg-plum10 focus-visible:outline-plum8'},
			{checked: true, color: 'info', className: 'bg-blue9 hover:bg-blue10 focus-visible:outline-blue8'},
			{checked: true, color: 'success', className: 'bg-green9 hover:bg-green10 focus-visible:outline-green8'},
			{
				checked: true,
				color: 'warning',
				className: 'bg-yellow9 hover:bg-yellow10 focus-visible:outline-yellow8',
			},
			{checked: true, color: 'danger', className: 'bg-red9 hover:bg-red10 focus-visible:outline-red8'},
		],
		defaultVariants: {checked: false, disabled: false, readonly: false, color: 'primary'},
	},
);

export const switchThumbStyles = cva(
	'absolute left-1/2 h-4 w-4 transform rounded-full bg-mauve1 ease-in-out dark:bg-mauve12',
	{
		variants: {
			changed: {true: 'transition-transform', false: 'transition-none'},
			checked: {true: 'translate-x-0', false: '-translate-x-full'},
		},
		defaultVariants: {changed: false, checked: false},
	},
);

export type SwitchVariantProps = VariantProps<typeof switchStyles>;
export type SwitchThumbVariantProps = VariantProps<typeof switchThumbStyles>;
