import {forwardRef} from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import omit from 'lodash.omit';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef, ElementRef} from 'react';
import {sliderThumbStyles} from './styles';
import type {SliderThumbVariantProps} from './styles';

export type SliderProps = ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
	range?: boolean;
} & SliderThumbVariantProps;

const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
	({className, disabled, range, readonly, ...props}, forwardedRef) => (
		<SliderPrimitive.Root
			{...omit(props, 'aria-label')}
			aria-readonly={props['aria-readonly'] || !!readonly}
			className={twMerge('relative flex w-full touch-none select-none items-center', className)}
			disabled={disabled || !!readonly}
			ref={forwardedRef}
		>
			<SliderPrimitive.Track
				className={twMerge(
					'relative h-2 w-full grow overflow-hidden rounded-full bg-mauve7',
					!disabled && !readonly && 'cursor-pointer',
				)}
			>
				<SliderPrimitive.Range className="absolute h-full bg-violet9" />
			</SliderPrimitive.Track>
			{range && (
				<SliderPrimitive.Thumb
					aria-label="Adjust slider start"
					className={sliderThumbStyles({disabled, readonly})}
				/>
			)}
			<SliderPrimitive.Thumb
				aria-label={props['aria-label'] || 'Adjust slider'}
				className={sliderThumbStyles({disabled, readonly})}
			/>
		</SliderPrimitive.Root>
	),
);
Slider.displayName = 'Slider';

export default Slider;
