import {forwardRef, useContext, useEffect, useId, useState} from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import {twMerge} from 'tailwind-merge';

import {FormControlContext} from '../form-control';
import {switchStyles, switchThumbStyles} from './styles';
import type {SwitchVariantProps} from './styles';

export type SwitchProps = Omit<SwitchPrimitives.SwitchProps & SwitchVariantProps, 'children'>;
const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
	({readonly, color, checked, disabled, className, id, ...props}, forwardedRef) => {
		const formControlProps = useContext(FormControlContext);
		const [changed, setChanged] = useState(false);
		const defaultId = useId();

		useEffect(() => {
			setChanged(true);
		}, [checked]);

		return (
			<SwitchPrimitives.Root
				{...props}
				aria-label={props['aria-label'] || 'Toggle switch'}
				aria-labelledby={formControlProps.id}
				checked={checked}
				className={twMerge(switchStyles({readonly, checked, disabled, color}), className)}
				disabled={disabled}
				id={id || `${formControlProps.id || defaultId}-field`}
				ref={forwardedRef}
			>
				<SwitchPrimitives.Thumb className={switchThumbStyles({checked: checked, changed})} />
			</SwitchPrimitives.Root>
		);
	},
);

Switch.displayName = 'Switch';

export default Switch;

export * from './styles';
