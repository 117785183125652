import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const sliderThumbStyles = cva(
	[
		'block h-6 w-6 rounded-full border-2 border-mauve7',
		'bg-mauve1 transition-colors hover:border-mauve8',
		'focus-visible:outline focus-visible:outline-1',
		'focus-visible:outline-mauve8 dark:bg-mauve12 cursor-grab active:cursor-grabbing',
	],
	{
		variants: {
			disabled: {true: 'opacity-40 pointer-events-none'},
			readonly: {true: 'pointer-events-none'},
		},
		defaultVariants: {disabled: false, readonly: false},
	},
);

export type SliderThumbVariantProps = VariantProps<typeof sliderThumbStyles>;
