'use client';

import {LayoutShell} from '~/app/_/components/layout-shell';
import {useSupabase} from '~/shared/hooks/use-supabase';
import clsx from 'clsx';
import {useParams} from 'next/navigation';

import type {PropsWithChildren} from 'react';
import {JobBoardFilter} from './filter';

export type JobBoardMainProps = PropsWithChildren;

export const JobBoardMain = ({children}: JobBoardMainProps) => {
	const {user} = useSupabase();
	const params = useParams();

	return (
		<main
			className={clsx(
				'flex w-full bg-mauve2',
				user ? 'grow' : 'h-full flex-col',
				!user && params.id ? 'min-h-full sm:min-h-[calc(100%-5rem)]' : 'min-h-[calc(100%-5rem)]',
			)}
		>
			<LayoutShell>
				<div className="relative flex h-full w-full flex-col gap-2">
					<JobBoardFilter />
					<div className="flex w-full flex-grow items-center gap-2 overflow-hidden">{children}</div>
				</div>
			</LayoutShell>
		</main>
	);
};
